import React, { Component } from 'react';
import Section from './Section';
import ShopGrid from './ShopGrid';
import { ListContextProvider } from 'pages/result/context';

class PublicationsPage extends Component {
  componentDidMount() {
    document.body.classList = '';
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Section />
        <ListContextProvider>
          <ShopGrid />
        </ListContextProvider>
      </React.Fragment>
    );
  }
}

export default PublicationsPage;
