import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

export default function useSearchQuery() {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [queries, setQueries] = useState({});
  const data = useMemo(() => new window.URLSearchParams(search), [search]);
  useEffect(() => {
    for (const entry of data.entries()) {
      const [param, value] = entry;
      setQueries((queries) => ({ ...queries, [param]: value }));
    }
  }, [search]);

  const queryToString = Object.entries(queries).reduce(
    (acc, [key, value]) => `${acc}${acc.length > 1 ? '&' : ''}${key}=${value}`,
    '?',
  );

  const addPath = (path, value) => {
    setQueries((queries) => ({ ...queries, [path]: encodeURI(value) }));
    navigate({
      pathname,
      search: createSearchParams({
        ...{ ...queries, [path]: value },
      }).toString(),
    });
  };

  return { queries, queryToString, addPath };
}
