import React from 'react';
import CountUp from 'react-countup';
import { Col, Container, Row } from 'reactstrap';
import ReviewsSlider from '../../components/Shared/ReviewsSlider';
import { reviews } from '../../common/data';

//import images
import bgImage from '../../assets/images/it/cta.jpg';

const Cta = () => {
  return (
    <React.Fragment>
      <section className="section pt-0">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div
                className="video-solution-cta position-relative"
                style={{ zIndex: '1' }}
              >
                <div
                  className="position-relative py-5 rounded"
                  style={{ background: `url(${bgImage}) top` }}
                >
                  <div
                    className="bg-overlay rounded bg-primary bg-gradient"
                    style={{ opacity: '0.8' }}
                  ></div>

                  <Row>
                    <Col lg={4} xs={6} className="my-4">
                      <div className="counter-box text-center px-lg-4">
                        <i className="uil uil-users-alt text-white-50 h2"></i>
                        <h1 className="mb-0 text-white">
                          <span className="counter-value">
                            <CountUp start={100} end={1327} duration={2} />
                          </span>
                          +
                        </h1>
                        <h6 className="counter-head text-white-50">
                          Toplam sayı
                        </h6>
                      </div>
                    </Col>

                    <Col lg={4} xs={6} className="my-4">
                      <div className="counter-box text-center px-lg-4">
                        <i className="uil uil-schedule text-white-50 h2"></i>
                        <h1 className="mb-0 text-white">
                          <span className="counter-value">
                            <CountUp start={1} end={27} duration={2} />
                          </span>
                          +
                        </h1>
                        <h6 className="counter-head text-white-50">
                          Yıllık arşiv
                        </h6>
                      </div>
                    </Col>

                    <Col lg={4} xs={6} className="my-4">
                      <div className="counter-box text-center px-lg-4">
                        <i className="uil uil-file-check-alt text-white-50 h2"></i>
                        <h1 className="mb-0 text-white">
                          <span className="counter-value">
                            <CountUp start={20000} end={22624} duration={2} />
                          </span>
                          +
                        </h1>
                        <h6 className="counter-head text-white-50">
                          Toplam sayfa
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <div className="feature-posts-placeholder bg-light"></div>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Cta;
