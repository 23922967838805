import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { basePath } from 'config';
import useModalStore from '../../../stores/modal';
import Cookies from 'universal-cookie';
import { BASE_TOKEN_NAME } from 'config';

export default function CardDetail({ imageUrl, publishDateText, viewerPath }) {
  const toggleModal = useModalStore(state => state.toggleModal);
  const cookies = new Cookies();

  const handleLink = (e) => {
    if (! cookies.get(BASE_TOKEN_NAME)) {
      e.preventDefault();
    
      toggleModal('premium', true);
    }
  }

  return (
    <Col lg={3} md={6} className="col-12 mt-4 pt-2">
        <Link target="_blank" to={basePath + viewerPath} onClick={handleLink}>
          <Card className="shop-list border-0 position-relative">
            <div className="shop-image position-relative overflow-hidden rounded shadow">
              <img src={imageUrl} className="img-fluid" alt="Landrick" />
              <div className="overlay-work">
                <div className="py-2 bg-soft-dark rounded-bottom out-stock">
                  <h6 className="mb-0 text-center">Okumak için tıklayın</h6>
                </div>
              </div>
            </div>
            <CardBody className="content pt-4 p-2">
              <div className="text-dark product-name h6">
                Agos Gazetesi
              </div>
              <div className="d-flex justify-content-between mt-1">
                <h6 className="text-dark small fst-italic mb-0 mt-1">
                  {publishDateText}
                </h6>
              </div>
            </CardBody>
          </Card>
      </Link>
    </Col>
  );
}
