// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';

class Faq extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light">
          <Container>
            <Row>
              <Col md="6" xs="12">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="help-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">Agos Arşivi nedir?</h5>
                    <p className="answer text-muted mb-0">
                      Agos Arşivi, 1996’dan beri çıkmakta olan gazetemize
                      dijital erişimin sağlandığı ve tüm sayılar içerisinde tam
                      metin arama yapılabilen yapay zeka temelli bir e-arşiv
                      uygulamasıdır.
                    </p>
                  </div>
                </div>
              </Col>

              <Col md="6" xs="12" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="help-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">
                      Agos Arşivinde nasıl arama yapılır?
                    </h5>
                    <p className="answer text-muted mb-0">
                      egazete.agos.com.tr ana sayfasındaki arama kutusuna,
                      aradığınız kelime ya da kelimeleri yazarak hızlı arama
                      yapabilirsiniz. Sistem size en doğru sonuçları ve
                      gazeteleri sunar. Ayrıca bu alana aradığınız bir gazetenin
                      gün ay ve yılını yazarak da gazeteye ulaşabilirsiniz.
                    </p>
                  </div>
                </div>
              </Col>

              <Col md="6" xs="12" className="mt-4 pt-2">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="help-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">
                      Agos Gazetesi'nin fiziki arşivini (kağıt) buradan temin
                      edebilir miyim?
                    </h5>
                    <p className="answer text-muted mb-0">
                      e-Arşiv uygulaması yalnızca dijital hizmet sağlar. Gazeteleri bilgisayar ortamında okuyabilir, istediğiniz sayfadan ekran resmi alabilirsiniz. Gerçek (kağıt baskı) kopyalar sadece yakın tarihli yayınlarda talep edilebilir. Eski arşiv gazetelerinin satışı bulunmamaktadır.
                    </p>
                  </div>
                </div>
              </Col>

              <Col md="6" xs="12" className="mt-4 pt-2">
                <div className="d-flex">
                  <i>
                    <FeatherIcon
                      icon="help-circle"
                      className="fea icon-ex-md text-primary me-2 mt-1"
                    />
                  </i>
                  <div className="flex-1">
                    <h5 className="mt-0">
                      Hesap oluşturmadan arşive erişebilir miyim?
                    </h5>
                    <p className="answer text-muted mb-0">
                      Maalesef! Arşiv içerisinde arama yapabilmek ve gazete
                      sayfalarını görüntüleyebilmek için{' '}
                      <Link
                        to="https://agos.com.tr/tr/e-gazete"
                        target="_blank"
                      >
                        agos.com.tr/tr/e-gazete
                      </Link>{' '}
                      linkinden üyelik oluşturmanız ve size uygun paketi satın
                      almanız gerekmektedir.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
              <Col xs={12} className="text-center">
                <div className="section-title">
                  <h4 className="title mb-4">Başka sorunuz var mı?</h4>
                  <p className="text-muted para-desc mx-auto">
                    Hemen irtibata geçin.
                  </p>
                  <Link to="/contact" className="btn btn-primary mt-4">
                    Şimdi iletişime geç
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default Faq;
