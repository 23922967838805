import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { basePath } from 'config';
import useModalStore from '../../stores/modal';
import Cookies from 'universal-cookie';
import { BASE_TOKEN_NAME } from 'config';

export default function ProductCard({
  imageUrl,
  name,
  publisherName,
  publishDateText,
  summary,
  viewerPath,
}) {
  const toggleModal = useModalStore(state => state.toggleModal);
  const cookies = new Cookies();

  const handleLink = (e) => {
    if (! cookies.get(BASE_TOKEN_NAME)) {
      e.preventDefault();
    
      toggleModal('premium', true);
    }
  }

  return (
    <Col xs={12} className="mt-4 pt-2">
      <Link target="_blank" to={basePath + decodeURI(viewerPath)} onClick={handleLink}>
        <Card className="shop-list border-0 shadow position-relative">
          <Row className="align-items-center g-0">
            <Col lg={2} md={6}>
              <div className="shop-image position-relative overflow-hidden">
                <img
                  src={imageUrl}
                  className="img-fluid"
                  style={{ width: 188, height: 235 }}
                  alt={summary}
                />
              </div>
            </Col>

            <Col lg={10} md={6}>
              <CardBody className="content p-4">
                <div className="text-dark product-name h6">
                  {name} — {publisherName}
                </div>
                <div className="d-lg-flex align-items-center mt-2 mb-3">
                  <h6 className="text-muted small fst-italic mb-0 me-3">
                    Yayın Tarihi: {publishDateText}
                  </h6>
                </div>
                <p className="text-muted mb-1">{summary}</p>
                <ul className="list-unstyled mt-3">
                  <li className="list-inline-item">
                    <div className="btn btn-primary">
                      Hemen Oku
                      <i className="ms-1 mdi mdi-arrow-right"></i>
                    </div>
                  </li>
                </ul>
              </CardBody>
            </Col>
          </Row>
        </Card>
      </Link>
    </Col>
  );
}
