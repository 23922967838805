import instance from 'api';

class PublishmentService {
  async search({ search, start, end, page, sort }) {
    const { data } = await instance.get('publishments/agos', {
      params: { search, start, end, page, sort },
      secure: false,
    });
    return data;
  }

  async getAll({ start, end, page, sort }) {
    const { data } = await instance.get('publishments/agos', {
      params: { start, end, page, sort },
      secure: false,
    });
    return data;
  }
}

export default new PublishmentService();
