import React, { useEffect } from 'react';

import Section from './Section';
import Aboutus from './Aboutus';
import Cta from './Cta';
import Popup from '../../components/Layout/popup';
import Pricing from './Pricing';
import Faq from './Faq';

const HomePage = () => {
  useEffect(() => {
    const scrollNavigation = () => {
      var doc = document.documentElement;
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      const navBar = document.getElementById('topnav');
      if (top > 80) {
        document
          .getElementById('topnav')
          .querySelectorAll('ul.navigation-menu li a')
          .forEach((item) => item.classList.remove('home-page'));
        navBar.classList.add('nav-sticky');
      } else {
        document
          .getElementById('topnav')
          .querySelectorAll('ul.navigation-menu li a')
          .forEach((item) => item.classList.add('home-page'));
        navBar.classList.remove('nav-sticky');
      }
    };

    window.addEventListener('scroll', scrollNavigation, true);

    return () => {
      // Temizlik işlevi, bileşen çözümlendiğinde veya yeniden renderlandığında çalışır.
      window.removeEventListener('scroll', scrollNavigation, true);
    };
  }, []);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Aboutus />
      </section>
      <Cta />
      <Pricing />
      <Faq />
      <Popup />
    </React.Fragment>
  );
};

export default HomePage;
