import { create } from 'zustand';

const useModalStore = create((set, get) => ({
  types: {
    premium: false,
  },
  data: null,
  cb: null,
  closeModal: (modal) => {
    set(() => ({ types: { [modal]: false } }));
  },
  toggleModal: (modal, status, data = null, cb = null) => {
    const _modal = get().types[modal];
    set(() => ({
      types: {
        [modal]: status ?? !_modal,
      },
      data,
      cb: cb,
    }));
  },
}));

export const { getState, setState, subscribe } = useModalStore;

export default useModalStore;
