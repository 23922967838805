import instance from 'api';
import { BASE_TOKEN_NAME } from 'config';
import Cookies from 'universal-cookie';

class AuthService {
  constructor() {
    this.cookies = new Cookies();
  }
  async verify() {
    const token = this.cookies.get(BASE_TOKEN_NAME);
    try {
      const { data } = await instance.post(
        'auth/validate',
        {
          token,
        },
        { secure: false },
      );
      return { success: data };
    } catch (error) {
      return {
        success: false,
        error: error?.response?.data?.messages?.[0] ?? error.message,
      };
    }
  }
}

export default new AuthService();
