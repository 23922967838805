import React from 'react';
import FeaturesPage from '../pages/features';
import PricesPage from '../pages/prices';
import HelpPage from '../pages/help';
import { Navigate } from 'react-router-dom';
import HomePage from '../pages/home';
import ContacePage from '../pages/contact';
import PublicationsPage from '../pages/publications';
import ResultPage from '../pages/result';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Navigate to="/home" />,
  },
  { path: '/home', component: HomePage, isTopbarDark: true },
  { path: '/features', component: FeaturesPage, isTopbarDark: true },
  { path: '/prices', component: PricesPage, isTopbarDark: true },
  { path: '/help', component: HelpPage, isTopbarDark: true },
  { path: '/contact', component: ContacePage, isTopbarDark: true },
  { path: '/publications', component: PublicationsPage, isTopbarDark: true },
  { path: '/result', component: ResultPage, isTopbarDark: true },
];

export default routes;
