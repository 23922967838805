import { createContext, useContext, useReducer } from 'react';
import ListReducer from './reducers';
import React from 'react';

const initialState = {
  pageNumber: 0,
  dates: {
    start: null,
    end: null,
  },
  sortBy: null,
};

export const ListContext = createContext(initialState);

export const ListContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ListReducer, initialState);

  return (
    <ListContext.Provider value={{ state, dispatch }}>
      {children}
    </ListContext.Provider>
  );
};

export const useListContext = () => useContext(ListContext);
