// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <Row className="mt-lg-4 align-items-center">
              <Col lg={5} md={12} className="text-center text-lg-start">
                <div className="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">
                  <h4 className="title mb-4">Üyelik Paketlerimiz</h4>
                  <p className="text-muted para-desc mx-auto mb-0">
                    Kredi kartı ile ödeme yapabildiğiniz gibi banka havalesi ile
                    de ödeme yapabilirsiniz.
                  </p>
                  <Link
                    to="/prices"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary mt-4"
                  >
                    Tüm paketleri görmek için tıklayınız
                  </Link>
                </div>
              </Col>

              <Col lg={7} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div className="ms-lg-5">
                  <Row className="align-items-center">
                    <Col md={6} className="col-12 px-md-0">
                      <Card className="pricing pricing-primary starter-plan shadow rounded border-0">
                        <CardBody className="py-5">
                          <h6 className="title name fw-bold text-uppercase mb-4">
                            6 Aylık (Türkiye)
                          </h6>
                          <div className="d-flex mb-4">
                            <span className="h4 mb-0 mt-2">₺</span>
                            <span className="price h1 mb-0">750</span>
                          </div>

                          <ul className="list-unstyled mb-0 ps-0">
                            <li className="h6 text-muted mb-0">
                              <span className="icon h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Bütün arşiv
                            </li>
                            <li className="h6 text-muted mb-0">
                              <span className="icon h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Tam metin arama
                            </li>
                            <li className="h6 text-muted mb-0">
                              <span className="icon h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Sırala ve Filtrele
                            </li>
                            <li className="h6 text-muted mb-0">
                              <span className="icon h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Paylaş
                            </li>
                          </ul>
                          <Link
                            to="https://www.agos.com.tr/tr/pages/abonelik"
                            className="btn btn-primary mt-4"
                            target="_blank"
                          >
                            Satın al
                          </Link>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col
                      md={6}
                      xs={12}
                      className="mt-4 pt-2 pt-sm-0 mt-sm-0 px-md-0"
                    >
                      <Card className="pricing pricing-primary bg-light shadow rounded border-0">
                        <CardBody className="py-5">
                          <h6 className="title name fw-bold text-uppercase mb-4">
                            Yıllık (Türkiye)
                          </h6>
                          <div className="d-flex mb-4">
                            <span className="h4 mb-0 mt-2">₺</span>
                            <span className="price h1 mb-0">1500</span>
                            <span className="h4 align-self-end mb-1">/yıl</span>
                          </div>

                          <ul className="list-unstyled mb-0 ps-0">
                            <li className="h6 text-muted mb-0">
                              <span className="icon h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Bütün arşiv
                            </li>
                            <li className="h6 text-muted mb-0">
                              <span className="icon h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Tam metin arama
                            </li>
                            <li className="h6 text-muted mb-0">
                              <span className="icon h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Sırala ve Filtrele
                            </li>
                            <li className="h6 text-muted mb-0">
                              <span className="icon h5 me-2">
                                <i className="uil uil-check-circle align-middle"></i>
                              </span>
                              Paylaş
                            </li>
                          </ul>
                          <Link
                            to="https://www.agos.com.tr/tr/pages/abonelik"
                            className="btn btn-primary mt-4"
                            target="_blank"
                          >
                            Satın al
                          </Link>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default Pricing;
