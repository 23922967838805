import React, { useEffect } from 'react';
import Layout from './components/Layout/';
import Cookies from 'universal-cookie';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './assets/css/materialdesignicons.min.css';
import './Apps.scss';

import { BASE_TOKEN_NAME } from 'config';
import withRouter from 'utils/with-router';

import routes from './routes/allRoutes';
import PremiumModal from 'components/Modal/Premium';
import SearchHintModal from './components/Modal/SearcHint';

function WithLayout({ WrappedComponent, hasDarkTopBar }) {
  return (
    <Layout hasDarkTopBar={hasDarkTopBar}>
      <WrappedComponent></WrappedComponent>
    </Layout>
  );
}

function App() {
  const [queryParams,] = useSearchParams();
  const cookies = new Cookies();

  useEffect(() => {
    if (queryParams.get('token')) {
      cookies.set(BASE_TOKEN_NAME, queryParams.get('token'), {
        domain: '.agos.com.tr'
      });
    }
  }, [queryParams.get('token')]);

  useEffect(() => {
    document.getElementById('topnav')?.classList.remove('nav-sticky-home');
  }, []);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <PremiumModal />
      <SearchHintModal />
      <React.Fragment>
        <Routes>
          {routes.map((route, idx) =>
            route.isWithoutLayout ? (
              <Route
                path={route.path}
                exact={route.exact}
                element={route.component}
                key={idx}
              />
            ) : (
              <Route
                path={route.path}
                exact
                element={
                  <WithLayout
                    WrappedComponent={route.component}
                    hasDarkTopBar={route.isTopbarDark}
                  />
                }
                key={idx}
              />
            ),
          )}
        </Routes>
      </React.Fragment>
    </QueryClientProvider>
  );
}

export default withRouter(App);
