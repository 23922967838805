import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { sortService } from 'services';
import { Placeholder, Col } from 'reactstrap';
import { useListContext } from 'pages/result/context';
import useSearchQuery from 'hooks/use-search-query';
import { addDays, format } from 'date-fns';
import DatePick from 'components/DatePicker';

export default function Sort() {
  const searchQuery = useSearchQuery();

  const { data, isLoading } = useQuery(
    ['sortList'],
    async () => sortService.getAll(),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  const { state } = useListContext();

  const updateDatePicker = (key, value) => {
    searchQuery.addPath(key, format(value, 'dd.MM.yyyy'));
  };

  return (
    <Col lg={7} md={5} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div
        className="d-flex justify-content-md-end align-items-center flex-wrap"
        style={{ gap: '10px' }}
      >
        <DatePick
          startDate={state.dates.start}
          onChange={(date) => updateDatePicker('start', date)}
          label="Başlangıç tarihini seç"
        />
        <DatePick
          startDate={state.dates.end}
          minDate={addDays(state.dates.start, 1)}
          onChange={(date) => updateDatePicker('end', date)}
          label="Bitiş tarihini seç"
        />
        <div className="form custom-form" style={{ minWidth: 207 }}>
          {isLoading || !data?.data ? (
            <Placeholder
              color="light"
              animation="wave"
              tag="div"
              xs={12}
              style={{
                width: '100%',
                height: '38px',
                borderRadius: '6px',
              }}
            />
          ) : (
            <div className="mb-0">
              <select
                value={state.sortBy}
                className="form-select form-control"
                id="Sortbylist-job"
                onChange={(e) => {
                  searchQuery.addPath('sort', e.target.value);
                }}
              >
                {Object.entries(data?.data.sort).map(([key, value], i) => (
                  <option value={key} key={i}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
}
