import React from 'react';
import { Placeholder, Col, Card, CardBody, Row } from 'reactstrap';

function SkeletonCard() {
  return (
    <Col xs={12} className="mt-4 pt-2">
      <Card className="shop-list border-0 shadow position-relative">
        <Row className="align-items-center g-0">
          <Col lg={2} md={6}>
            <div className="shop-image position-relative overflow-hidden">
              <Placeholder
                color="primary"
                style={{ width: 188, height: 220 }}
                tag="div"
                animation="wave"
              />
            </div>
          </Col>
          <Col lg={10} md={6}>
            <CardBody className="content p-4">
              <Placeholder
                animation="wave"
                color="light"
                style={{ width: 105, height: 22 }}
                className="text-dark product-name h6"
                tag="p"
              ></Placeholder>

              <div className="d-lg-flex align-items-center mt-2 mb-3">
                <h6 className="text-muted small fst-italic mb-0 me-3 d-flex gap-2">
                  <Placeholder
                    color="light"
                    style={{ height: 21, width: 150 }}
                    tag="p"
                    animation="wave"
                  />
                </h6>
              </div>
              <Placeholder
                color="light"
                style={{ height: 51, width: '100%' }}
                tag="p"
                animation="wave"
              />
              <ul className="list-unstyled mt-3">
                <li className="list-inline-item">
                  <Placeholder
                    color="light"
                    style={{ width: 153, height: 43, borderRadius: '6px' }}
                    tag="p"
                    animation="wave"
                  />
                </li>
              </ul>
            </CardBody>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default function Skeleton() {
  const length = 10;
  return Array.from({ length }).map((_, i) => <SkeletonCard key={i} />);
}
