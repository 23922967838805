import { useListContext } from 'pages/result/context';
import useSearchQuery from './use-search-query';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { publishmentService } from 'services';

function changeDateChunk(date) {
  if (!date || date?.length === 0) return null;
  const _date = date.toString().replaceAll('.', '-');
  const [day, month, year] = _date.split('-');
  return new Date(year, +month - 1, day);
}

export default function useResultFilter(avoidQuery = false) {
  const searchQuery = useSearchQuery();

  const {
    query = '',
    start = null,
    end = null,
    page = 1,
    sort = 'DATE-10',
  } = searchQuery.queries;
  const { state, dispatch } = useListContext();

  useEffect(() => {
    if (!start && !end) return;
    dispatch({
      type: 'SET_WITH_KEY',
      payload: {
        key: 'dates',
        value: {
          start: changeDateChunk(start),
          end: changeDateChunk(end),
        },
      },
    });
  }, [start, end]);

  useEffect(() => {
    if (!sort) return;
    dispatch({
      type: 'SET_WITH_KEY',
      payload: { key: 'sortBy', value: sort },
    });
  }, [sort]);

  useEffect(() => {
    if (page <= 0) return;
    dispatch({
      type: 'SET_WITH_KEY',
      payload: {
        key: 'pageNumber',
        value: +page,
      },
    });
  }, [page]);

  const service = avoidQuery
    ? publishmentService.getAll
    : publishmentService.search;

  const { data, isLoading } = useQuery(
    [`publishment-result-${+avoidQuery}`, start, end, page, sort, query],
    async () =>
      service({
        start,
        end,
        page,
        sort,
        search: query,
      }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!query || avoidQuery,
      keepPreviousData: true,
    },
  );

  return { data, isLoading, ...searchQuery, state };
}
