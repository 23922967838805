import useSearchQuery from 'hooks/use-search-query';
import React from 'react';
import {
  Col,
  Pagination as PaginationWrapper,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

const PAGE_SIZE = 10;
export default function Pagination({ data }) {
  const { queries, addPath } = useSearchQuery();
  function generatePagination(currentPage) {
    const totalCount = data?.totalCount ?? 80;
    const totalPages = Math.ceil(totalCount / PAGE_SIZE);

    const pagination = [];

    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(currentPage + 2, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pagination.push(i);
    }

    if (totalPages <= currentPage) {
      pagination.push(currentPage);
      if (pagination.length === 1 && currentPage !== 1) pagination.unshift(1);
    }
    return pagination;
  }

  if (data?.totalCount === 0) return null;

  const pageNumber = +(queries?.page ?? 1);
  const pages = generatePagination(pageNumber);

  return (
    <Col xs={12} className="mt-4 pt-2">
      <PaginationWrapper listClassName="justify-content-center mb-0">
        <PaginationItem disabled={pageNumber <= 1}>
          <PaginationLink
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              addPath('page', pageNumber - 1);
            }}
          >
            <i className="mdi mdi-arrow-left me-1"></i>Önceki
          </PaginationLink>
        </PaginationItem>
        {pages.map((_page, i) => (
          <PaginationItem key={i} active={_page === pageNumber}>
            <PaginationLink
              style={{ borderRadius: '0' }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                addPath('page', _page);
              }}
            >
              {_page}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={Boolean(!data?.hasNextPage)}>
          <PaginationLink
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              addPath('page', pageNumber + 1);
            }}
          >
            Sonraki<i className="mdi mdi-arrow-right ms-1"></i>
          </PaginationLink>
        </PaginationItem>
      </PaginationWrapper>
    </Col>
  );
}
