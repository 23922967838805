import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Sort from 'components/Sort';
import ProductCard from 'components/ProductCard';
import useResultFilter from 'hooks/use-result-filter';
import ProductCardSkeleton from 'components/ProductCard/skeleton';
import Pagination from 'components/Pagination';

export default function List() {
  const { data, isLoading } = useResultFilter();

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center">
            <Col lg={5} md={7}>
              <div className="section-title">
                <h6 className="mb-0">
                  Yaklaşık {data?.totalCount ?? 0} sonuç bulundu
                </h6>
              </div>
            </Col>

            <Sort />
          </Row>

          <Row>
            {isLoading || !data?.data ? (
              <ProductCardSkeleton />
            ) : (
              data?.data.map((product, key) => (
                <ProductCard key={key} {...product} />
              ))
            )}
            <Pagination data={data} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
