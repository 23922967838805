import React from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  ModalFooter,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import useModalStore from 'stores/modal';
import { parentDomain } from 'config';

export default function PremiumModal() {
  const opened = useModalStore((store) => store.types.premium);
  const data = useModalStore((store) => store.data);
  const closeModal = useModalStore((store) => store.closeModal);

  return (
    <Modal
      role="dialog"
      isOpen={opened}
      toggle={() => closeModal('premium')}
      centered
    >
      <ModalHeader>Giriş yapın ya da paket alın</ModalHeader>

      <ModalBody>
        <div className="bg-white rounded box-shadow">
          <Form>
            <Row>
              <Col>Sayfaların içeriğini görüntüleyebilmek için giriş yapmanız gerekmektedir. Satın aldığınız bir paket varsa, lütfen aşağıdaki “Giriş Yap” butonuna tıklayınız. Eğer henüz bir paket satın almadıysanız, lütfen bir paket satın alınız.</Col>
            </Row>
          </Form>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Link to={parentDomain + 'account'} target="_blank">
          <Button type="button" color="primary" outline>
            Giriş Yap
          </Button>
        </Link>
        <Link to={'/prices'}>
          <Button type="button" color="primary" onClick={() => closeModal('premium')}>
            Paket Satın Al
          </Button>
        </Link>
      </ModalFooter>
    </Modal>
  );
}
