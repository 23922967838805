import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';

import useResultFilter from 'hooks/use-result-filter';
import Sort from 'components/Sort';
import Pagination from 'components/Pagination';
import CardDetail from './Card';
import ProductSkeleton from './Card/skeleton';

export default function ShopGrid() {
  const { data, isLoading } = useResultFilter(true);

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center">
            <Col lg={5} md={7}>
              <div className="section-title">
                <h5 className="mb-0">Tüm Sayılar</h5>
              </div>
            </Col>

            <Sort />
          </Row>

          <Row>
            {isLoading || !data?.data ? (
              <ProductSkeleton />
            ) : (
              data.data.map((product, key) => (
                <CardDetail key={key} {...product} />
              ))
            )}

            <Pagination data={data} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
