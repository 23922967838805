import React from 'react';
import { Col, Card, CardBody, Placeholder } from 'reactstrap';

export default function Skeleton() {
  return Array.from({ length: 20 }).map((_, i) => <ProductSkeleton key={i} />);
}

function ProductSkeleton() {
  return (
    <Col lg={3} md={6} className="col-12 mt-4 pt-2">
      <Card className="shop-list border-0 position-relative">
        <div className="shop-image position-relative overflow-hidden rounded shadow">
          <Placeholder
            color="primary"
            style={{ width: '100%', height: 350 }}
            tag="div"
            animation="wave"
            className="img-fluid"
          />
        </div>
        <CardBody className="content pt-4 p-2">
          <Placeholder
            color="light"
            style={{ width: 120, height: 22 }}
            tag="p"
            animation="wave"
          />
          <div className="d-flex justify-content-between mt-1">
            <Placeholder
              color="light"
              style={{ width: 92, height: 22 }}
              tag="h6"
              animation="wave"
              className="text-dark small fst-italic mb-0 mt-1"
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
