import React from 'react';
import { Alert } from 'reactstrap';
import useLocalStorage from 'hooks/use-local-storage';

const Popup = () => {
  const [store, setStore] = useLocalStorage('agreePolicy');

  if (store) return null;

  return (
    <React.Fragment>
      <Alert
        isOpen={store}
        toggle={() => setStore(true)}
        className="card cookie-popup shadow rounded py-3 px-4"
      >
        <p className="text-muted mb-0 fs-6">
          Bu web sitesi size harika bir kullanıcı deneyimi sunmak için çerezleri
          kullanır. Bunu kullanarak çerez politikamızı kabul etmiş olursunuz.
        </p>
        <div className="cookie-popup-actions text-end"></div>
      </Alert>
    </React.Fragment>
  );
};

export default Popup;
