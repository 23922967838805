import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import useModalStore from 'stores/modal';

export default function SearchHintModal() {
  const opened = useModalStore((store) => store.types.searchhint);
  const closeModal = useModalStore((store) => store.closeModal);

  return (
    <Modal
      role="dialog"
      isOpen={opened}
      toggle={() => closeModal('searchhint')}
      centered
    >
      <ModalHeader>Doküman içi arama ipuçları:</ModalHeader>

      <ModalBody>
        <div className="bg-white rounded box-shadow">
          <ul type="square">
            <li>Ermenice dokümanlarda latin harfli arama yapabilirsiniz.</li>
            <li>Latin harfli arama yapmanıza rağmen herhangi bir sonuç bulamadıysanız arama kutucuğunda bulunan klavye ikonuna tıklayarak Ermeni harfleri ile arayabilirsiniz.</li>
            <li>Yalnızca tam eşleşen kelime ya da kelime gruplarını bulmak için tırnak işaretlerini <kbd>“</kbd> <kbd>”</kbd> kullanabilirsiniz.<br /><br /> <bold>Örneğin;</bold> <kbd>“vakıf”</kbd> ya da <kbd>“Hrant Dink”</kbd></li>
            <li>Aradığınız kelime ya da kelime gruplarının geçtiği tüm sonuçları görüntülemek için yalın/eksiz hallerini yazarak aramanızı zenginleştirebilirsiniz.<br /><br /> <bold>Örneğin;</bold> olduğundan, olanlar, olduğu vs. sonuçları için sadece <kbd>olan</kbd>, <kbd>oldu</kbd> yazabilirsiniz.</li>
          </ul>
        </div>
      </ModalBody>
    </Modal>
  );
}
