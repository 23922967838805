import React, { useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import FeatherIcon from 'feather-icons-react';
import styles from './style.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';

registerLocale('tr', tr);

export default function DatePick({ onChange, startDate, label, minDate }) {
  const [date, setDate] = useState();
  const selectedDate = useMemo(() => {
    if (typeof date === 'undefined') return startDate;
    return date;
  }, [startDate, date]);
  return (
    <div
      className={`d-flex justify-content-between  align-items-center ${styles.wrapper}`}
    >
      <DatePicker
        locale="tr"
        popperClassName={styles.popper}
        selected={selectedDate}
        className={styles.datePicker}
        minDate={minDate}
        placeholderText={label}
        onChange={(date) => {
          if (typeof onChange === 'function') onChange(date);
          setDate(date);
        }}
      />
      <FeatherIcon icon="calendar" size="16px" />
    </div>
  );
}
