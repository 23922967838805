const ListReducer = (state, action) => {
  switch (action.type) {
    case 'SET_WITH_KEY': {
      const { key, value } = action?.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};

export default ListReducer;
