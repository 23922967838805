import React, { useEffect } from 'react';
import List from './List';
import Section from './Section';
import { ListContextProvider } from './context';

function ResultPage() {
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  return (
    <React.Fragment>
      <Section />
      <ListContextProvider>
        <List />
      </ListContextProvider>
    </React.Fragment>
  );
}

export default ResultPage;
