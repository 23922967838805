import instance from 'api';

class SortService {
  async getAll() {
    const { data } = await instance.get('filters/document-search', {
      secure: false,
    });
    return data;
  }
}

export default new SortService();
